let baseUrl = "";
let stripePK = "";
if (window.location.host === "a-table.app") {
  baseUrl = "https://atable.falconconsulting.fr";
  // stripePK = "pk_test_51I8OVEIoisbGOB9xG5sR3xz7zqHXLrEaf8cfoSQAZHF2tx9LHjnKojgt7AtqyGEJuUUMfgNjosEcOLofNBAhuxND001qGUUEeY"
   stripePK = "pk_live_51I8OVEIoisbGOB9xwcaWlMnLt1Icr0Pw3GbrBac5XjDjywJlhqI4eeX4EkC4tFTIRqws88lP5avbGZ7PTmafg1er00eSaxp8Ah";
} else {
  baseUrl = "http://localhost:1000";
  stripePK = "pk_test_51I8OVEIoisbGOB9xG5sR3xz7zqHXLrEaf8cfoSQAZHF2tx9LHjnKojgt7AtqyGEJuUUMfgNjosEcOLofNBAhuxND001qGUUEeY";
}

module.exports = { baseUrl, stripePK };
