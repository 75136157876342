import React, { useEffect } from "react";
import Home from "./containers/Home";
import AddBank from "./containers/AddBank";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { baseUrl, stripePK } from "./config";
import "react-toastify/dist/ReactToastify.css";

const stripePromise = loadStripe(stripePK);

let path = window.location.pathname.slice(0, 9);
let nativeToken = window.location.pathname.slice(9);

function App() {
  const [inNative, setInNative] = React.useState(false);
  const [userFound, setUserFound] = React.useState(false);
  const [nativeUser, setNativeUser] = React.useState();

  useEffect(async () => {
    if (path === "/addbank/") {
      if (nativeToken.length > 0) {
        await axios
          .get(`${baseUrl}/resturants/verify`, {
            headers: {
              Authorization: "Bearer " + nativeToken,
            },
          })
          .then((res) => {
            setNativeUser(res.data);
            setInNative(true);
            setUserFound(true);
          })
          .catch((err) => {
            setUserFound(false);
            setInNative(true);
          });
      }
    }
  }, [inNative]);

  return (
    <Elements stripe={stripePromise}>
      <ToastContainer limit={1} />
      <div className="App">
        <Switch>
          {path === "/addbank/" ? (
            <Route path="/addbank">
              <AddBank
                nativeUser={nativeUser}
                inNative={userFound}
                nativeToken={nativeToken}
              />
            </Route>
          ) : (
            <Route component={Home} path="/" exact />
          )}
          <Route component={Home} path="/" exact />
          <Redirect to="/" />
        </Switch>
      </div>
    </Elements>
  );
}

export default App;
